import { format } from 'date-fns';
import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';

import { AsyncTask } from '../../../domain/async/AsyncTask';
import { ViewModel } from '../../../domain/core/ViewModel';
import { GroupSubscriptionModel } from '../../../domain/model/GroupSubscriptionModel';
import { GroupSubscriptionUserModel } from '../../../domain/model/GroupSubscriptionUserModel';
import { I18nService } from '../../../domain/service/I18nService';
import { StripeService } from '../../../domain/service/StripeService';
import { TrackingEvent } from '../../../domain/service/tracking/TrackingEvent';
import { TrackingService } from '../../../domain/service/tracking/TrackingService';
import { SessionStore } from '../../../domain/store/SessionStore';
import { transient } from '../../../inversify/decorator';
import {
  STRIPE_SUBSCRIPTION_STATUS
} from '../../../shared/enum/stripe/stripeSubscriptionStatus.enum';

@transient()
export class ManageGroupVm extends ViewModel {

  @observable
  public groupSubscriptionInfo: GroupSubscriptionModel = new GroupSubscriptionModel();

  constructor(
    @inject(StripeService) private readonly stripeService: StripeService,
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(TrackingService) private readonly tracking: TrackingService,
    @inject(SessionStore) public readonly sessionStore: SessionStore,
  ) {
    super();
    makeObservable(this);
  }

  public override onInit = async () => {
    await Promise.all([
      this.getSubscriptionInfo.run(),
    ]);
  }

  @computed
  public get dataLoadingInProgress(): boolean {
    return this.getSubscriptionInfo.isBusy;
  }

  @computed
  public get isIncompleteOrPastDue(): boolean {
    const { groupSubscriptionStatus } = this.groupSubscriptionInfo;
    return groupSubscriptionStatus === STRIPE_SUBSCRIPTION_STATUS.INCOMPLETE || groupSubscriptionStatus === STRIPE_SUBSCRIPTION_STATUS.PAST_DUE;
  }

  @computed
  public get groupSubscriptionStatusTranslation(): string {
    const { groupSubscriptionStatus } = this.groupSubscriptionInfo;

    if (groupSubscriptionStatus) {
      const i18nKey = `manage_group:group_status.${groupSubscriptionStatus}`;
      return this.i18n.t(i18nKey);
    } else {
      return this.i18n.t('manage_group:group_status.active');
    }
  }

  @computed
  public get groupSubscriptionActionTranslation(): string {
    const { canceledAt } = this.groupSubscriptionInfo;
    const i18nKey = `manage_group:group_subscription_action.${canceledAt ? 'canceled' : 'active'}`;

    return this.i18n.t(i18nKey);
  }

  @computed
  public get allParticipantsOfGroup(): Set<GroupSubscriptionUserModel> {
    if (!this.groupSubscriptionInfo.owner) return new Set();

    const members = new Set([this.groupSubscriptionInfo.owner, ...this.groupSubscriptionInfo.members]);

    // * Create empty seat members to ensure proper table rendering
    while (members.size < this.groupSubscriptionInfo.quantity) {
      members.add(new GroupSubscriptionUserModel());
    }

    return members;
  }

  @computed
  public get expiresAt(): string | undefined {
    if (this.groupSubscriptionInfo.expiresAt) {
      return format(new Date(this.groupSubscriptionInfo.expiresAt), 'dd.MM.yyyy.');
    }
  }

  @action
  public setGroupSubscriptionInfo = (groupSubscriptionInfo: GroupSubscriptionModel) => {
    this.groupSubscriptionInfo = groupSubscriptionInfo;
  }

  public getSubscriptionInfo = new AsyncTask(async () => {
    const response = await this.stripeService.getSubscriptionInfo();

    if (response) {
      return this.setGroupSubscriptionInfo(response);
    }
  })

  public removeUserFromGroup = new AsyncTask(async (selectedUserForRemoval: GroupSubscriptionUserModel, coupon: string | null | undefined) => {
    await this.stripeService.removeUserFromGroupSubscription(selectedUserForRemoval.id, coupon);
    await this.getSubscriptionInfo.run();
  })

  public removeUnassignedSeat = new AsyncTask(async () => {
    await this.stripeService.removeSeatFromGroupSubscription();
    await this.getSubscriptionInfo.run();
  })

  public handleClickOnALink = () => {
    this.tracking.track(TrackingEvent.PROFILE_MULTIPLE_SUBSCRIPTIONS_CANCEL);
  };

}
