import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, Grid, ThemeProvider, useMediaQuery } from '@mui/material';

import { useVm } from '../../../domain/hooks/useVm';
import { LoadingFallback } from '../../../LoadingFallback';
import { useThemeContext } from '../../../theme/ThemeContextProvider';
import { AddSeatsSection } from './components/AddSeatsSection';
import { GroupProductBlocker } from './components/GroupProductBlocker';
import { GroupProductInfo } from './components/GroupProductInfo';
import { GroupTitle } from './components/GroupTitle';
import {
  GroupSubscriptionStepperModal
} from './group-subscription-stepper/GroupSubscriptionStepperModal';
import { GroupSubscriptionVm } from './GroupSubscriptionVm';
import { GroupOverviewPrice } from './overview/GroupOverviewPrice';
import { GroupOverviewTable } from './overview/GroupOverviewTable';

export const GroupSubscription = observer(function GroupSubscription() {
  const { theme } = useThemeContext();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('desktop'));
  const { t } = useTranslation();

  const vm = useVm(GroupSubscriptionVm);

  const groupProductContent = useMemo(() => (
    vm.groupMembersOverview.size === 1
      ? <GroupProductBlocker />
      : <GroupProductInfo startDate={vm.startDate} endDate={vm.endDate} />
  ), [vm.endDate, vm.groupMembersOverview.size, vm.startDate]);

  const addSeatsSection = useMemo(() => <AddSeatsSection
    candidatesForAssignment={vm.candidatesForAssignment}
    assignedMembers={vm.assignedMembers}
    setAssignedMembers={vm.setAssignedMembers}
  />, [vm.candidatesForAssignment, vm.assignedMembers, vm.setAssignedMembers]);

  const groupOverviewTable = useMemo(() => <GroupOverviewTable groupOverviewData={vm.groupOverviewWithOwner} />, [vm.groupOverviewWithOwner]);

  const groupOverviewPrice = useMemo(() => <GroupOverviewPrice groupSubscriptionVm={vm} />, [vm]);

  if (vm.fetchData.isBusy) {
    return <LoadingFallback />;
  }

  return (
    <ThemeProvider theme={theme}>
      <GroupSubscriptionStepperModal />

      <Grid container alignItems={'center'} justifyContent={'center'} bgcolor={theme.palette.background.surface}>
        <Grid container direction={'column'} paddingY={{ xs: 3, md: 6 }} rowGap={isMobileSize ? 2 : 4} maxWidth={{ xs: '95%', sm: '90%', lg: '70%', xl: '60%' }} alignItems={'center'}>
          {/* TITLE */}
          <GroupTitle title={t('group_subscription:title.upgrade')} />

          {/* SUBSCRIPTION PRODUCT CONTENT */}
          {groupProductContent}

          {/* ADD SEATS SECTION */}
          {addSeatsSection}

          {/* TABLE */}
          {groupOverviewTable}

          <Grid item>
            <Divider />
          </Grid>

          {/* PRICE OVERVIEW */}
          {groupOverviewPrice}
        </Grid>

      </Grid>
    </ThemeProvider>
  );
});
