import { inject } from 'inversify';

import { singleton } from '../../inversify/decorator';
import {
  StripeInvoiceLineItemResponseDto
} from '../../shared/dto/stripe/stripe.invoice.lineitem.response.dto';
import { StripeInvoiceResponseDto } from '../../shared/dto/stripe/stripe.invoice.response.dto';
import {
  StripePaymentMethodResponseDto
} from '../../shared/dto/stripe/stripe.payment.method.response.dto';
import { StripeProductIdsDto } from '../../shared/dto/stripe/stripe.product.ids.dto';
import { StripeProductResponseDto } from '../../shared/dto/stripe/stripe.product.response.dto';
import {
  GroupSubscriptionDeleteRequestDto
} from '../../shared/dto/subscription/groupSubscription.delete.request.dto';
import {
  GroupSubscriptionPostRequestDto
} from '../../shared/dto/subscription/groupSubscription.post.request.dto';
import {
  GroupSubscriptionPutRequestDto
} from '../../shared/dto/subscription/groupSubscription.put.request.dto';
import {
  GroupSubscriptionResponseDto
} from '../../shared/dto/subscription/groupSubscription.response.dto';
import {
  SubscriptionUserResponseDto
} from '../../shared/dto/subscription/groupSubscriptionUser.response.dto';
import {
  SubscriptionAnonymousPostRequestDto
} from '../../shared/dto/subscription/subscription.anonymous.post.request.dto';
import { GroupSubscriptionModel } from '../model/GroupSubscriptionModel';
import { GroupSubscriptionUserModel } from '../model/GroupSubscriptionUserModel';
import { StripeInvoiceLineItemModel } from '../model/stripe/StripeInvoiceLineItemModel';
import { StripeInvoiceModel } from '../model/stripe/StripeInvoiceModel';
import { StripePaymentMethodModel } from '../model/stripe/StripePaymentMethodModel';
import { StripePriceModel } from '../model/stripe/StripePriceModel';
import { StripeProductModel } from '../model/stripe/StripeProductModel';
import { AjaxService } from '../service/AjaxService';
import { Proxy } from './Proxy';

export interface StripeProductResponse {
  id: string;
  name: string;
  price: StripePriceModel;
}

export interface IStripeSessionResponse {
  url: string;
}

@singleton()
export class StripeProxy extends Proxy {

  constructor(@inject(AjaxService) private readonly ajax: AjaxService) {
    super();
  }

  public getProducts = async (dto: StripeProductIdsDto) => {
    const result = await this.ajax.post<StripeProductResponseDto[]>('stripe/products', dto);
    if (result.ok) {
      return this.withData(result, result.data.map(StripeProductModel.fromDto));
    }

    return result;
  }

  public redeemGroupVoucher = async (code: string) => {
    const result = await this.ajax.post('stripe/redeem-group-voucher', { code });
    return result;
  }

  public getRelatedMembers = async () => {
    const httpResult = await this.ajax.get<SubscriptionUserResponseDto[]>('subscription/related-members');
    if (httpResult.ok) {
      return this.withData(
        httpResult,
        httpResult.data.map(user => GroupSubscriptionUserModel.fromDto(user))
      );
    }

    return httpResult;
  }

  public getUpcomingInvoiceLines = async (quantity?: number | undefined) => {
    const result = await this.ajax.get<StripeInvoiceLineItemResponseDto[]>(`stripe/upcoming-invoice/lines${quantity !== undefined ? `?quantity=${quantity}` : ''}`);
    if (result.ok) {
      return this.withData(result, result.data.map(item => StripeInvoiceLineItemModel.fromDto(item)));
    }
    return result;
  }

  public getUpcomingInvoice = async (quantity?: number | undefined) => {
    const result = await this.ajax.get<StripeInvoiceResponseDto>(`stripe/upcoming-invoice${quantity !== undefined ? `?quantity=${quantity}` : ''}`);
    if (result.ok) {
      return this.withData(result, StripeInvoiceModel.fromDto(result.data));
    }
    return result;
  }

  public getPaymentMethods = async () => {
    const result = await this.ajax.get<StripePaymentMethodResponseDto[]>('stripe/payment-methods');
    if (result.ok) {
      return this.withData(result, result.data.map(data => StripePaymentMethodModel.fromDto(data)));
    }
    return result;
  }

  public getGroupSubscriptionInfo = async () => {
    const httpResult = await this.ajax.get<GroupSubscriptionResponseDto>('subscription');
    if (httpResult.ok) {
      return this.withData(
        httpResult,
        GroupSubscriptionModel.fromDto(httpResult.data)
      );
    }

    return httpResult;
  }

  public updateGroupSubscription = async (data: GroupSubscriptionPutRequestDto) => {
    const result = await this.ajax.put<GroupSubscriptionResponseDto>('/subscription', data);

    if (result.ok) {
      return this.withData(result, GroupSubscriptionModel.fromDto(result.data));
    }

    return result;
  }

  public removeUserFromGroupSubscription = async (dto: GroupSubscriptionDeleteRequestDto) => {
    return await this.ajax.delete<void>('/subscription/member', dto);
  }

  public removeSeatFromGroupSubscription = async () => {
    return await this.ajax.delete<void>('/subscription/seat');
  }

  public createCheckoutSession = async (groupSubscriptionPostRequestDto: GroupSubscriptionPostRequestDto) => {
    const result = await this.ajax.post<IStripeSessionResponse>('/subscription', groupSubscriptionPostRequestDto);
    if (result.ok) {
      return this.withData(result, result.data);
    }
    return result;
  }

  public createAnonymousCheckoutSession = async (subscriptionAnonymousDto: SubscriptionAnonymousPostRequestDto) => {
    const result = await this.ajax.post<IStripeSessionResponse>('stripe/create-checkout-session-anonymous', subscriptionAnonymousDto);
    if (result.ok) {
      return this.withData(result, result.data);
    }

    return result;
  }

  public createPortalSession = async () => {
    const result = await this.ajax.post<IStripeSessionResponse>('stripe/create-portal-session');
    if (result.ok) {
      return this.withData(result, result.data);
    }
    return result;
  }

}
