import { action, computed, makeObservable, observable } from 'mobx';

import { ViewModel } from '../../../../../domain/core/ViewModel';
import { transient } from '../../../../../inversify/decorator';
import { AssignSeatOptionType, IAssignAreaMembersModalProps } from './AssignAreaMembersModal';
import { GroupSubscriptionUserModel } from '../../../../../domain/model/GroupSubscriptionUserModel';

@transient()
export class AssignAreaMembersModalVm extends ViewModel<IAssignAreaMembersModalProps> {

  @observable
  public open: boolean = false;

  @observable
  public assignSeatOption: AssignSeatOptionType = 'existing_members';

  public assignSeatOptions: AssignSeatOptionType[] = [
    'existing_members',
    // 'email_invite'
  ];

  @observable
  public tempUsers: Set<GroupSubscriptionUserModel> = new Set();

  constructor() {
    super();
    makeObservable(this);
  }

  @computed
  public get disableAssign(): boolean {
    return Boolean(!this.tempUsers.size);
  }

  @action
  public setOpenModal = (open: boolean) => {
    this.open = open;
  }

  @action
  public setAssignSeatOption = (option: AssignSeatOptionType) => {
    this.assignSeatOption = option;
  }

  @action
  public setTempUsers = (users: Set<GroupSubscriptionUserModel>) => {
    this.tempUsers = users;
  }

  public onAssignSeats = () => {
    const { assignedMembers, setAssignedMembers } = this.props;

    /**
     * Implementation like this doesn't work, I don't know why:
     * const combinedSet = new Set([...selectedUsers, ...this.tempUsers]);
     */
    const combinedSet = new Set([...Array.from(assignedMembers), ...Array.from(this.tempUsers)]);

    setAssignedMembers(combinedSet);

    this.closeModal();
  };


  public closeModal = () => {
    this.setOpenModal(false);
    this.props.onCloseModal();
    this.setTempUsers(new Set());
  }

}
