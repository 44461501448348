import { makeObservable, observable } from 'mobx';

import {
  GroupSubscriptionResponseDto
} from '../../shared/dto/subscription/groupSubscription.response.dto';
import { STRIPE_SUBSCRIPTION_STATUS } from '../../shared/enum/stripe/stripeSubscriptionStatus.enum';
import { GroupSubscriptionUserModel } from './GroupSubscriptionUserModel';
import { GroupSubscriptionPutRequestDto } from '../../shared/dto/subscription/groupSubscription.put.request.dto';

export class GroupSubscriptionModel {

  @observable
  public subscriptionId: string = '';

  @observable
  public owner: GroupSubscriptionUserModel | null = null;

  @observable
  public groupSubscriptionStatus: STRIPE_SUBSCRIPTION_STATUS = STRIPE_SUBSCRIPTION_STATUS.ACTIVE;

  // * quantity = owner + num_of_members + emptySeats
  @observable
  public quantity: number = 0;

  @observable
  public purchasedAt: string | null = null;

  @observable
  public canceledAt: string | null = null;

  @observable
  public expiresAt: string | null = null;

  @observable
  public members: GroupSubscriptionUserModel[] = [];

  @observable
  public coupon: string | null | undefined = null;

  @observable
  public paymentResolveUrl: string | null | undefined = null;

  @observable
  public status: STRIPE_SUBSCRIPTION_STATUS = STRIPE_SUBSCRIPTION_STATUS.INCOMPLETE;

  /** Represents an empty seat after the owner has removed a user from a group subscription.
  The seat is already paid for, and the owner can reassign it to another or same user. */
  @observable
  public emptySeats: number = 0;

  constructor() {
    makeObservable(this);
  }

  public static fromDto = (dto: GroupSubscriptionResponseDto) => {
    const model = new GroupSubscriptionModel();
    model.subscriptionId = dto.subscriptionId;
    model.owner = GroupSubscriptionUserModel.fromDto(dto.owner);
    model.purchasedAt = dto.purchasedAt;
    model.groupSubscriptionStatus = dto.status;
    model.quantity = dto.quantity;
    model.canceledAt = dto.canceledAt;
    model.expiresAt = dto.expiresAt;
    model.members = dto.members.map(member => GroupSubscriptionUserModel.fromDto(member));
    model.coupon = dto.coupon;
    model.paymentResolveUrl = dto.paymentResolveUrl;
    model.status = dto.status;
    model.emptySeats = dto.emptySeats;

    return model;
  }

  public toDto = (allMembersIds: string[], coupon: string | null = null, quantity: number, discount: number | undefined = undefined): GroupSubscriptionPutRequestDto => {
    const dto: GroupSubscriptionPutRequestDto = {
      subscriptionId: this.subscriptionId,
      ownerId: this.owner?.id || '',
      members: allMembersIds,
      coupon: coupon,
      quantity: quantity,
      discount: discount,
    };

    return dto;
  };
}
