import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { TableCell, Typography, useMediaQuery } from '@mui/material';

import { GroupSubscriptionUserModel } from '../../../../../domain/model/GroupSubscriptionUserModel';
import { useThemeContext } from '../../../../../theme/ThemeContextProvider';

interface ISeatCellProps {
  index: number;
  row: GroupSubscriptionUserModel;
  isGroupOwner: boolean;
}

export const SeatCell: React.FC<ISeatCellProps> = function SeatCell({ index, row, isGroupOwner }: ISeatCellProps) {
  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('desktop'));

  return (
    <TableCell component="th" scope="row" padding="none" sx={{ paddingLeft: 2 }}>
      <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.secondary}>
        {`${t('group_subscription:table.table_row.seat.seat')} ${index + 1} - `}
      </Typography>

      {/* Unassigned seat check. */}
      {row.id
        ? <Typography variant={isMobileSize ? 'text_md_semibold' : 'text_sm_semibold'} color={theme.palette.text.primary}>
          {isGroupOwner ? `${t('group_subscription:table.table_row.seat.you')}` : (row.fullName ?? row.email)}
        </Typography>
        : <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.secondary}>
          {row.fullName ?? row.email}
        </Typography>
      }
    </TableCell>
  );
};
