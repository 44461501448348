import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { UserSearchIcon } from '@HunterCompanion/hunter-design/icons';
import { Grid, TableBody, TableCell, TableRow, Typography } from '@mui/material';

import { useTablePagination } from '../../../../domain/hooks/useTablePagination';
import { GroupSubscriptionUserModel } from '../../../../domain/model/GroupSubscriptionUserModel';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import { SvgWrapper } from '../../../../toolkit/components/SvgWrapper';
import { TableLayout } from '../../../../toolkit/components/table/TableLayout';
import { GroupOverviewType } from '../../types/GroupOverviewTypes';
import { GroupOverviewTableHead } from './GroupOverviewTableHead';
import { GroupOverviewTableRow } from './GroupOverviewTableRow';

interface IGroupOverviewTableProps {
  groupOverviewData: GroupOverviewType;
}

const EmptyState = () => {
  const { theme } = useThemeContext();
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell colSpan={6}>
        <Grid container direction={'column'} alignItems={'center'} justifyContent={'center'}>
          <Grid item>
            <SvgWrapper icon={UserSearchIcon} width={36} height={36} fill={theme.palette.brand?.brand_color} />
          </Grid>
          <Grid item>
            <Typography variant='text_md_semibold' color={theme.palette.text.primary}>
              {t('group_subscription:no_seats_1')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='text_md_regular' color={theme.palette.text.secondary}>
              {t('group_subscription:no_seats_2')}
            </Typography>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export const GroupOverviewTable = observer(function GroupOverviewTable({ groupOverviewData }: IGroupOverviewTableProps) {
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, emptyRows, visibleRows } = useTablePagination<GroupSubscriptionUserModel>(5);
  const { groupMembersOverview, assignedMembers } = groupOverviewData;

  const removeMember = React.useCallback((member: GroupSubscriptionUserModel) => {
    assignedMembers.delete(member);
  }, [assignedMembers]);

  const groupOverviewTableHead = React.useMemo(() => <GroupOverviewTableHead />, []);

  const memoizedRows = React.useMemo(
    () => visibleRows(Array.from(groupMembersOverview)).map((row, index) => (
      <GroupOverviewTableRow
        key={row.key}
        groupOverviewData={groupOverviewData}
        index={index}
        row={row}
        removeMember={removeMember}
      />
    )),
    [groupMembersOverview, visibleRows, groupOverviewData, removeMember]
  );

  const renderTableBodyContent = React.useMemo(() => {
    return groupMembersOverview.size === 0 ? <EmptyState /> : memoizedRows;
  }, [groupMembersOverview.size, memoizedRows]);

  return (
    <TableLayout
      count={groupMembersOverview.size}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    >
      {groupOverviewTableHead}
      <TableBody>
        {renderTableBodyContent}
        {emptyRows(groupMembersOverview.size) > 0 && (
          <TableRow style={{ height: 53 * emptyRows(groupMembersOverview.size) }}>
            <TableCell colSpan={6} />
          </TableRow>
        )}
      </TableBody>
    </TableLayout>
  );
});
