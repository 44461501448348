import { StripeProductModel } from '../domain/model/stripe/StripeProductModel';
import { STRIPE_PRICING_MODEL } from '../shared/enum/stripe/stripePricingModel.enum';

export const fetchAndFilterProducts = async (
  fetchProductsFunction: (productsIds: string[]) => Promise<StripeProductModel[]>,
  productsIds: string[],
  includeMultiUserProduct: boolean = false
): Promise<StripeProductModel[]> => {
  const allProducts = await fetchProductsFunction(productsIds);

  const singleUserProducts = allProducts.filter((product) => !product.tiered);
  const revenueCatProducts = singleUserProducts.filter(product => productsIds.includes(product.id));
  const sortedByPrice = revenueCatProducts.sort((a, b) => a.price!.priceAmount - b.price!.priceAmount);

  if (includeMultiUserProduct) {
    const multiUserProductByLanguage = allProducts
      .filter((product) => product.tiered)
      .find((product) => product.metadata?.pricingModel === STRIPE_PRICING_MODEL.GRADUATED);

    if (multiUserProductByLanguage) {
      sortedByPrice.push(multiUserProductByLanguage);
    }
  }

  const arrayWithPlusSubscriptionAtTheEnd = sortedByPrice.sort((a, b) => {
    if (a.name.includes('PLUS')) {
      return 1; // Move "PLUS" to the end
    } else if (b.name.includes('PLUS')) {
      return -1; // Keep "PLUS" at the end
    } else {
      return 0; // Maintain the relative order of other elements
    }
  });

  return arrayWithPlusSubscriptionAtTheEnd;
};
