import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';

import { AsyncTask } from '../../domain/async/AsyncTask';
import { ViewModel } from '../../domain/core/ViewModel';
import { StripeProductModel } from '../../domain/model/stripe/StripeProductModel';
import { I18nService } from '../../domain/service/I18nService';
import { StripeService } from '../../domain/service/StripeService';
import { TrackingEvent } from '../../domain/service/tracking/TrackingEvent';
import { TrackingService } from '../../domain/service/tracking/TrackingService';
import { SessionStore } from '../../domain/store/SessionStore';
import { transient } from '../../inversify/decorator';
import {
  GroupSubscriptionPostRequestDto
} from '../../shared/dto/subscription/groupSubscription.post.request.dto';
import { SUBSCRIPTION_TYPE } from '../../shared/enum';
import { fetchAndFilterProducts } from '../../util/StripeUtil';

@transient()
export class PricingRouteVm extends ViewModel {

  @observable
  public products: StripeProductModel[] = [];

  constructor(
    @inject(SessionStore) private readonly sessionStore: SessionStore,
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(TrackingService) private readonly tracking: TrackingService,
    @inject(StripeService) public readonly stripeService: StripeService,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get isLoading(): boolean {
    return this.getOfferings.isBusy || this.getProducts.isBusy;
  }

  @computed
  private get alreadyPlusSubscription(): boolean {
    return Boolean(this.sessionStore.currentUser?.subscriptionType === SUBSCRIPTION_TYPE.PLUS);
  }

  @action
  private setProducts = (products: StripeProductModel[]): void => {
    this.products = products;
  }

  /**
   *  Works only if fullname is exactly like this: "Monthly some other_words"
   *  the first word in fullname is ${name} part of locize key. So, eg:
   *    fullname => "Monthly some other_words"
   *    first word, aka ${name} => "monthly"
   *    locize key => payment:subscription_monthly
   */
  @action
  public getTranslation = (fullname: string) => {
    const name = fullname.toLowerCase().split(' ')[0];
    return this.i18n.t(`payment:subscription_${name}`);
  };

  public getOfferings = new AsyncTask(async (): Promise<string[] | undefined> => {
    return await this.stripeService.fetchRevenueCatOfferings();
  })

  public getProducts = new AsyncTask(async (productsIds: string[] = []): Promise<void> => {
    const products = await fetchAndFilterProducts(this.stripeService.fetchProducts, productsIds, true);

    this.setProducts(this.alreadyPlusSubscription ? products.slice(0, -1) : products);
    await this.tracking.track(TrackingEvent.PRICING_PAGE_VIEW);
  })

  /**
   * This createCheckoutSession is used only for NON group products.
   * For group products we use one from 'GroupSubscriptionVm'.
  */
  public createCheckoutSession = new AsyncTask(async (product: StripeProductModel, comingFromInAppBrowser: boolean = false): Promise<void> => {
    const subscriptionPostRequestDto: GroupSubscriptionPostRequestDto = {
      productId: product.id,
      priceId: product.price.priceId,
      members: [],
      trial: this.sessionStore.allowTrialPeriod,
      comingFromInAppBrowser,
      quantity: 1,
      couponId: product.coupon?.id
    };

    await this.stripeService.createCheckoutSessionForSingleProduct(subscriptionPostRequestDto);
  })

}
